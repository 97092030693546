<template>
  <router-view />
</template>

<script>
// FOR ADMIN TO SHOW SPECIFIC USER`S AUCTIONS
export default {
  components: {},
  computed: {
    userId() {
      return this.$route.params.user;
    },
    shouldReload() {
      return this.$store.getters.shouldReload;
    },
  },
};
</script>
